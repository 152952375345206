import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import axios from 'axios';

const ConfirmationPage = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const idos = query.get('token');
  const [status, setStatus] = useState(null);
  const [name, setName] = useState('');
  const [dateFormatted, setDateFormatted] = useState('');
  const [addressFormatted, setAddressFormatted] = useState('');
  const [address, setAddress] = useState('');
  const [stateos, setStateos] = useState('');
  const [description, setDescription] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://osserver.apidev.cloud/', { params: { idos } });
        const data = response.data;
        setName(data.name);
        setDateFormatted(data.date_formatted);
        setAddressFormatted(data.address_formatted);
        setAddress(data.address);
        setStateos(data.stateos);
        setDescription(data.description);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, [idos]);

  const handleConfirm = async () => {
    try {
      await axios.post('https://osserver.apidev.cloud/confirm', { idos });
      setStatus('confirmed');
      window.location.reload();
    } catch (error) {
      console.error('Error confirming:', error);
    }
  };

  const handleCancel = async () => {
    try {
      await axios.post('https://osserver.apidev.cloud/cancel', { idos });
      setStatus('cancelled');
      window.location.reload();
    } catch (error) {
      console.error('Error canceling:', error);
    }
  };

  return (
<div className="container">
  <div className="logo text-center">
    <img src="https://srv139.sonik.apidev.cloud/soniklogoazul.png" alt="Sonik" className="logo-img" />
  </div>
  {status === null && stateos === -1 && (
    <>
      <div className="heading text-center">
        Olá <strong>{name}</strong>, você tem uma visita técnica agendada conosco!
      </div>
      <div className="appointment-details text-center">
        <p><strong>Motivo da Visita:</strong> {description}</p>
        <p><strong>Data:</strong> {dateFormatted}</p>
        <p><strong>Endereço:</strong> {address}</p>
      </div>
    </>
  )}
  {stateos === -1 && (
    <>
      <p className="text-center">Por favor, confirme sua visita técnica</p>
      <div className="actions text-center">
        <button onClick={handleConfirm} className="button button-accept">Confirmar Visita</button>
        <button onClick={handleCancel} className="button button-decline">Cancelar Visita</button>
      </div>
    </>
  )}
  {status === 'confirmed' && (
    <>
    <div className="flex justify-center mb-4">
      <h2 className="confirmation-text">Visita confirmada!</h2>
      <div className="appointment-details text-center">
      <p><strong>Data:</strong> {dateFormatted}</p>
      <p><strong>Endereço:</strong> {address}</p>
    </div>
    </div>
    <p className="text-center">
      Por favor, certifique-se de que um <strong>adulto esteja presente</strong> no local no momento da visita para receber o técnico.
    </p>
  </>
  )}
  {status === 'cancelled' && (
    <>
      <div className="flex justify-center mb-4">
        <h2 className="cancellation-text">Visita cancelada</h2>
      </div>
    </>
  )}
  {stateos === 1 && (
    <>
      <div className="flex justify-center mb-4">
        <h2 className="confirmation-text">Visita confirmada!</h2>
        <div className="appointment-details text-center">
        <p><strong>Data:</strong> {dateFormatted}</p>
        <p><strong>Endereço:</strong> {address}</p>
      </div>
      </div>
      <p className="text-center">
        Por favor, certifique-se de que um <strong>adulto esteja presente</strong> na residência no momento da visita para receber o técnico.
      </p>
    </>
  )}
  {stateos === 2 && (
    <>
      <div className="flex justify-center mb-4">
        <h2 className="cancellation-text">Visita cancelada</h2>
      </div>
    </>
  )}
  {status === null && stateos === -1 && (
    <>
      <div className="map-container text-center">
        <a href={`https://www.google.com/maps/search/?api=1&query=${addressFormatted}`} target="_blank" rel="noopener noreferrer">
          <img src={`https://maps.googleapis.com/maps/api/staticmap?center=${addressFormatted}&zoom=16&size=600x300&maptype=roadmap&markers=color:red%7C${addressFormatted}&key=AIzaSyC3nVEhjOwm3wh0vETAImXoQG-v1KX50pg`} 
          alt="Google Maps" className="map-img"/>
        </a>
      </div>
      <div className="message text-center">
        Clique no mapa acima para ver a localização no Google Maps.
      </div>
    </>
  )}

  <div className="contact text-center">
    <p>Sonik</p>
    <p>Av. Jove Soares, 993</p>
    <p>Cerqueira Lima, Itaúna - MG, 35680-352</p>
    <p>0800 242 1000</p>
  </div>
</div>

  );
};

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<ConfirmationPage />} />
      </Routes>
    </Router>
  );
}

export default App;
