// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f4f4f9;
}

.container {
  max-width: 600px;
  margin: 20px;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.logo img {
  max-width: 200px;
  margin-bottom: 20px;
}

.heading {
  font-size: 24px;
  margin-bottom: 20px;
}

.appointment-details {
  font-size: 18px;
  margin-bottom: 20px;
}

.button-accept {
  display: inline-block;
  padding: 10px 20px;
  margin: 10px;
  background-color: #4CAF50;
  color: white;
  text-decoration: none;
  border-radius: 5px;
}

.button-decline {
  display: inline-block;
  padding: 10px 20px;
  margin: 10px;
  background-color: #af4c4c;
  color: white;
  text-decoration: none;
  border-radius: 5px;
}

.message {
  font-size: 14px;
  color: #666;
  margin-bottom: 20px;
}

.contact {
  font-size: 14px;
  color: #666;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,8BAA8B;EAC9B,SAAS;EACT,UAAU;EACV,aAAa;EACb,uBAAuB;EACvB,mBAAmB;EACnB,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,gBAAgB;EAChB,YAAY;EACZ,aAAa;EACb,uBAAuB;EACvB,kBAAkB;EAClB,uCAAuC;EACvC,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,mBAAmB;AACrB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;EAClB,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,WAAW;AACb","sourcesContent":["body {\n  font-family: Arial, sans-serif;\n  margin: 0;\n  padding: 0;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  height: 100vh;\n  background-color: #f4f4f9;\n}\n\n.container {\n  max-width: 600px;\n  margin: 20px;\n  padding: 20px;\n  background-color: white;\n  border-radius: 8px;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n  text-align: center;\n}\n\n.logo img {\n  max-width: 200px;\n  margin-bottom: 20px;\n}\n\n.heading {\n  font-size: 24px;\n  margin-bottom: 20px;\n}\n\n.appointment-details {\n  font-size: 18px;\n  margin-bottom: 20px;\n}\n\n.button-accept {\n  display: inline-block;\n  padding: 10px 20px;\n  margin: 10px;\n  background-color: #4CAF50;\n  color: white;\n  text-decoration: none;\n  border-radius: 5px;\n}\n\n.button-decline {\n  display: inline-block;\n  padding: 10px 20px;\n  margin: 10px;\n  background-color: #af4c4c;\n  color: white;\n  text-decoration: none;\n  border-radius: 5px;\n}\n\n.message {\n  font-size: 14px;\n  color: #666;\n  margin-bottom: 20px;\n}\n\n.contact {\n  font-size: 14px;\n  color: #666;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
